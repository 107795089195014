import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TransitionsModal from "./common/TransitionsModal";
import LuckyWheel from "./LuckyWheel/LuckyWheel";
import userService from "../service/user.service";
import { ConfigContext } from "../ConfigContext";
import useToast from "../utils/useToast";
import PromotionDescription from "./PromotionDescription";
import DailyCheckin from "./DailyCheckin";
import DailyCheckinV2 from "./DailyCheckinV2/DailyCheckinV2";

function Reward({ getWalletInfo, walletInfo, checkin }) {
  const navigate = useNavigate();
  const [openLuckyWheel, setOpenLuckyWheel] = useState(false);
  const [tokens, setTokens] = useState(0);
  const [rebate, setRebate] = useState(0);
  const [openRebate, setOpenRebate] = useState(false);
  const [openDailyCheckin, setOpenDailyCheckin] = useState(false);
  const [openMail, setOpenMail] = useState(false);
  const [messageBox, setMessageBox] = useState(false);
  const [mails, setMails] = useState([]);
  const [message, setMessage] = useState("");
  const configData = useContext(ConfigContext);
  const toast = useToast();
  const [rank, setRank] = useState(null)
  const [checkinObject, setCheckinObject] = useState(null);


  const fetchTokens = async () => {
    try {
      const response = await userService.getTokens();
      setTokens(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const dailyRebate = async () => {
    try {
      const rebateResponse = await userService.getDailyRebate();
      setRebate(rebateResponse.data[0]);
    } catch (err) {
      console.log(err);
    }
  };
  console.log("rebate", rebate);

  const getMail = async () => {
    try {
      const mailResponse = await userService.getMail();
      setMails(mailResponse.data);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchDailyCheckins = async () => {
    try {
      const { data } = await userService.getDailyCheckinsV2();
      if (!data) {
        setCheckinObject(null);
      }
      setCheckinObject(data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchDailyCheckins();
  },[localStorage.getItem("user")])

  useEffect(() => {
    getMail();
    dailyRebate();
  }, []);

  useEffect(() => {
    if (walletInfo && walletInfo.rank) {
        const userRank = walletInfo.rank.split(" ")
        console.log("🚀 ~ useEffect ~ userRank:", userRank)
        if (userRank[1]) {
            setRank(userRank[1])
        }
    }
  }, [walletInfo]);

  const handleClick = (type) => {
    switch (type) {
      case "level":
        
        break;
      case "checkin":
        setOpenDailyCheckin(true);
        break;
      case "spin":
        setOpenLuckyWheel(true);
        break;
      case "redeem":
        setOpenRebate(true);
        break;
      case "message":
        setOpenMail(true);
        break;
    
      default:
        break;
    }
  }

  return (
    <>
      <div className="reward-boxs">
        {toast.ToastContainer}
        {
          configData && configData.available_rewards ? 
          configData.available_rewards.split(',').map((reward) => {
            return (
            <div className="reward-box" onClick={() => {
              handleClick(reward)
            }}>
              <p>{reward === "level" ?  `RANK` : reward.toUpperCase()}</p>
              <div className="">
                {`${reward}_reward` === "level_reward" ?
                 rank ? <div class="col-12 text-nowrap d-flex flex-col justify-content-center align-items-center">
                      <img src={require("../images/trophy-icon.png")} alt="" width="25px" height="25px" />
                      <p style={{ fontWeight: "bold", color: configData ? configData.text_color_secondary : "" }}>
                          {rank}
                      </p>
                  </div> 
                    : 
                    <div style={{ width:"50px", height:"50px"}}> No Rank </div>
                  :
                  <img
                    src={configData[`${reward}_reward`]}
                    alt=""
                    width="50px"
                    height="50px"
                    style={{ objectFit: "contain" }}
                  />
                }

              </div>
            </div>
          )}) : null
        }
      </div>
      {openLuckyWheel && (
        <TransitionsModal
          title={"Lucky Wheel"}
          titleStyle={{
            fontSize: "1em",
            textTransform: "uppercase",
            padding: "0",
          }}
          open={true}
          onClose={() => setOpenLuckyWheel(false)}
          containerStyle={{
            width: "100%",
            maxWidth: "500px",
            padding: "0",
          }}
          style={{
            textAlign: "center",
            borderRadius: "15px",
            padding: "1.5rem 1rem",
            color: "white",
          }}
        >
          <p className="text-left">Tokens: {tokens ? tokens : 0}</p>
          <LuckyWheel
            selectedIndex={4}
            getWalletInfo={getWalletInfo}
            fetchTokens={fetchTokens}
          />
        </TransitionsModal>
      )}
        {openDailyCheckin && (
        <TransitionsModal
          title={"Daily Check-In"}
          titleStyle={{
            fontSize: "1em",
            textTransform: "uppercase",
            padding: "0",
          }}
          open={true}
          onClose={() => setOpenDailyCheckin(false)}
          containerStyle={{
            width: "99%",
            maxWidth: "500px",
            padding: "0",
          }}
          style={{
            textAlign: "center",
            borderRadius: "15px",
            padding: "1.5rem 1rem",
            color: "white",
          }}
        >
          <DailyCheckinV2 checkin={checkinObject} fetchDailyCheckins={fetchDailyCheckins} getWalletInfo={getWalletInfo} />
          {/* <p className="text-left">Tokens: {tokens ? tokens : 0}</p>
          <LuckyWheel
            selectedIndex={4}
            getWalletInfo={getWalletInfo}
            fetchTokens={fetchTokens}
          /> */}
        </TransitionsModal>
      )}
      {openRebate && (
        <TransitionsModal
          title={rebate ? rebate.title : "Rewards"}
          titleStyle={{
            fontSize: "21px",
            textTransform: "uppercase",
          }}
          open={rebate}
          onClose={() => setOpenRebate(false)}
          style={{
            textAlign: "center",
            borderRadius: "15px",
            padding: "1.5rem 1rem",
            color: "white",
          }}
          containerStyle={{maxHeight: "98%" }}
        >
          <div
            className="promo_modal"
            style={{ maxHeight: "80vh", overflowY: "auto" }}
          >
            <img src={rebate.image} alt="" style={{width:"100%", height:"auto"}} />
            <p className="text-md font-medium mt-2" style={{color:"white"}}>
              {rebate && rebate.description ? (
                <PromotionDescription
                  field={rebate.description}
                  data={rebate.others}
                />
              ) : null}
            </p>
            <div>
              {rebate.others.applicable &&
                (!rebate.can_claim_promo_group
                  ? !rebate.group_applied
                  : true) && (
                  <>
                    <button
                      className={`btn btn-sm bg-red-dark rounded-xs text-uppercase
                                            font-700 btn-l mt-3 me-2 h-12`}
                      onClick={() => {
                        setOpenRebate(null);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        userService
                          .claimDailyRebate(rebate.id)
                          .then((res) => {
                            toast.success("Promotion claimed successfully!");
                            setOpenRebate(null);
                          })
                          .catch((err) => {
                            toast.error(
                              `Failed to claim reward! Please try again`
                            );
                          });
                      }}
                      className={`btn btn-sm bg-green-dark rounded-xs text-uppercase
                                            font-700 btn-l mt-3 h-12`}
                    >
                      Claim
                    </button>
                  </>
                )}
            </div>
          </div>
        </TransitionsModal>
      )}
      {openMail && (
        <TransitionsModal
          title={"Mailbox"}
          titleStyle={{
            fontSize: "21px",
            textTransform: "uppercase",
          }}
          open={true}
          onClose={() => setOpenMail(false)}
          style={{
            textAlign: "center",
            borderRadius: "15px",
            padding: "1.5rem 1rem",
            color: "white",
          }}
        >
          <div className="mail_container text-left">
            {mails && mails.length ? (
              mails.map((mail) => (
                <div
                  className="single-mail d-flex justify-content-between align-items-center cursor-pointer mb-2"
                  style={{ border: "2.5px solid #ffc107" }}
                  onClick={async () => {
                    setMessageBox(true);
                    setMessage(mail.message);
                    await userService.updateIsRead(mail.id, {
                      id: mail.id,
                      created_at: mail.created_at,
                      receiver: mail.receiver,
                      mail_template: mail.mail_template,
                      is_read: true,
                    });
                  }}
                >
                  <p className="mail-title">
                    <span
                      className="text-uppercase font-bold"
                      style={{ color: configData ? configData.text_color : "" }}
                    >
                      TITLE:{" "}
                    </span>
                    {mail.title}
                  </p>
                  <p className="mail-title ml-auto mr-3">
                    <span
                      className="text-uppercase font-bold"
                      style={{ color: configData ? configData.text_color : "" }}
                    >
                      FROM:{" "}
                    </span>
                    {mail.sender}
                  </p>
                  {!mail.is_read && (
                    <span className="w-3 h-3 rounded-circle bg-green-500" />
                  )}
                </div>
              ))
            ) : (
              <p className="w-100 text-center">No Messages</p>
            )}
          </div>
        </TransitionsModal>
      )}
      {messageBox && (
        <TransitionsModal
          title={"Mailbox"}
          titleStyle={{
            fontSize: "21px",
            textTransform: "uppercase",
          }}
          open={true}
          onClose={() => setMessageBox(false)}
          style={{
            textAlign: "center",
            borderRadius: "15px",
            padding: "1.5rem 1rem",
            color: "white",
            maxHeight: "50vh",
            overflowY: "scroll",
          }}
        >
          <div className="text-left">
            {message ? (
              <div className="d-flex justify-content-between cursor-pointer">
                <p className="mail-title w-100">
                  <span
                    className="font-bold"
                    style={{
                      color: configData ? configData.text_color_secondary : "",
                    }}
                  >
                    MESSAGE:{" "}
                  </span>
                  <span
                    className="d-flex flex-column justify-content-center align-items-center"
                    dangerouslySetInnerHTML={{ __html: message }}
                  />
                </p>
              </div>
            ) : (
              <p className="w-100 text-center">No Message</p>
            )}
          </div>
        </TransitionsModal>
      )}
    </>
  );
}

export default Reward;
