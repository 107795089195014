import React from 'react'
import Footer from '../components/Footer'
import MenuNotification from '../components/MenuNotification'
import MenuSidebar from '../components/MenuSidebar'
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { ConfigContext } from '../ConfigContext';
import { useState } from 'react';
import Loading from '../components/Loading';
import AuthService from "../service/auth.service";
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';
import UserService from "../service/user.service";
import Profile2 from '../components/layout/Profile/Profile2';
import Profile3 from '../components/layout/Profile/Profile3';
import TransitionsModal from '../components/common/TransitionsModal';
import LuckyWheel from '../components/LuckyWheel/LuckyWheel';
import DailyCheckinV2 from '../components/DailyCheckinV2/DailyCheckinV2';

const Profile = (
    { walletInfo,
        setAuth, getWalletInfo }
) => {
    const configData = useContext(ConfigContext)
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [turnOver, setTurnOver] = useState(null)
    const [currentUser, setCurrentUser] = useState(null);
    const [rank, setRank] = useState(null)
    const [progressCash, setProgressCash] = useState(null)
    const [progressChip, setProgressChip] = useState(null)
    // const [wallet, setWallet] = useState("");
    const [openLuckyWheel, setOpenLuckyWheel] = useState(false);
    const [checkinObject, setCheckinObject] = useState(null);
    const [checkIn, setCheckIn] = useState(false);
    const { t } = useTranslation();
    const [tokens, setTokens] = useState(0);

    const fetchDailyCheckins = async () => {
        try {
          const { data } = await UserService.getDailyCheckins();
          if (!data) {
            setCheckinObject(null);
          }
          setCheckinObject(data);
        } catch (err) {
          console.log(err);
        }
      };

    const getTurnover = async () => {
        try {
            const turnover = await UserService.getTurnover();
            if (turnover.data && turnover.data.length) {
                setTurnOver(turnover.data[0])
            }
        } catch (error) {
            console.log(error)
        }
    }
    // const getWalletInfo = async () => {
    //     // setLoading(true);
    //     try {
    //         const wallet = await UserService.getBalance();
    //         setWallet(wallet.data);
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };
    const fetchTokens = async () => {
        try {
          const response  = await UserService.getTokens();
          setTokens(response.data);
        } catch (err) {
          console.log(err);
        }
    };

    useEffect(() => {
        const fetchWalletInfo = async () => {
            const user = AuthService.getCurrentUser();
            if (user) {
                await getWalletInfo();
                fetchDailyCheckins();
                setCurrentUser(user);
            }
        };
        fetchWalletInfo();
        getTurnover();
    }, [localStorage.getItem("user")]);


    useEffect(() => {
        if (walletInfo && walletInfo.rank) {
            const userRank = walletInfo.rank.split(" ")
            if (userRank[1]) {
                setRank(userRank[1])
            }
        }
        getTurnover()
    }, [walletInfo]);

    useEffect(() => {
        if (walletInfo && walletInfo.balance && turnOver && turnOver.min_turnover) {
          const balance = parseFloat(walletInfo.balance).toFixed(2);
          const chips_balance = parseFloat(walletInfo.chips_balance).toFixed(2);
          const min_turnover = parseFloat(turnOver.min_turnover).toFixed(2);
          const progressCashRate = ((balance / min_turnover)*100).toFixed(2);
          const progressChipRate = ((chips_balance / min_turnover)*100).toFixed(2);
      
          setProgressCash(progressCashRate);
          setProgressChip(progressChipRate);
        //   if (progressWithdrawRate >= 1) {
        //   } else {
        //     const progressWithdrawRatePercent = (progressWithdrawRate * 100).toFixed(2);
        //     // setProgressWithdraw(`${progressWithdrawRatePercent}%`);
        //   }
        }
      }, [turnOver, walletInfo]);

    return (
        <>
            {
                (configData && (configData.profile_style == "1")) || (configData && !configData.profile_style)?
                <div className="t1">
                    <div>
                        <div className="page">
                            <div class="container-fluid pb-4 body flex-grow-1 d-flex flex-column" style={{ marginBottom: "66px" }}>
                                <div class="row d-flex py-3 px-1 align-items-center bg-dashboard">
                                    <div class="col-auto">
                                        <img src={require("../images/Profile/test_avatar.png")} style={{ height: "100px", width: "100px" }} />
                                    </div>
                                    <div class="col-5">
                                        <div class="row" style={{ fontSize: "0.9rem" }}>
                                            <div class="col-12 text-nowrap p-1">
                                                <span style={{ fontWeight: "bold", color: configData ? configData.text_color_secondary : "" }} class="profile-text">
                                                    {walletInfo && walletInfo.username
                                                        ? walletInfo.username
                                                        : t("No_Account")} &nbsp;</span>
                                                {/* <span style={{ color: configData ? configData.text_color : "" }} >(Basic)</span> */}
                                            </div>

                                            <div class="col-12 text-nowrap p-1" style={{ color: configData ? configData.text_color : "" }}>
                                                Wallet Balance:
                                                <span class="font-weight-bold">
                                                    &nbsp;RM&nbsp;
                                                    {currentUser && walletInfo ? walletInfo.balance : "0.00"}
                                                </span>
                                            </div>
                                            <div class="col-12 text-nowrap p-1">
                                                <form method="post" action="/Login/Logout">
                                                    <button class="btn btn-sm py-1 px-3 btn-logout" onClick={(e) => {
                                                        e.preventDefault();
                                                        AuthService.logout();
                                                        window.SBF.logout();
                                                        setCurrentUser(null);
                                                        sessionStorage.removeItem("popup_isonce_onsite")
                                                        navigate("/signin");
                                                        setAuth(false)
                                                    }}>
                                                        {t("Log_out")}
                                                    </button>
                                                    <input name="__RequestVerificationToken" type="hidden" value="CfDJ8AnQWo2teYBAklgDxEGuGmTqjGkF8VYdT_DT-VYwMahMjGlkVD6ww8GI_IayL6MExUqk4xa90yw5o8tPG95iiPg4PvjBdOXJb297WLLT9my_f5RLDCTIGh8IFB7jNz5g9L4rhnItCzG0M4FhlMJ2L6qAT-NWGBy3iZII3AVcfTi955nD4st11n13F9zWKoWppw" /></form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 d-flex justify-content-end">
                                        <div style={{ fontSize: "0.9rem" }} className='d-flex justify-content-center align-items-center'>
                                            {rank ? <div class="col-12 text-nowrap p-1 d-flex flex-col justify-content-center align-items-center">
                                                <img src={require("../images/trophy-icon.png")} alt="" width="30px" height="30px" />
                                                <p style={{ fontWeight: "bold", color: configData ? configData.text_color_secondary : "" }}>
                                                    {rank}
                                                </p>
                                            </div> : ""}
                                        </div>
                                    </div>
                                </div>
                                <div class="row p-4" >
                                    <div class="w-100 p-3 profile-winover">
                                        <p class="my-1" style={{ color: configData ? configData.text_color : "" }}>
                                            {t("Bonus_Winover_Requirement")}:<br />
                                            <span style={{ fontSize: "1.2rem" }} class="profile-text">
                                                <span style={{ color: configData ? configData.text_color : "" }}>{t("Cash")}: </span>
                                                <span style={{ color: configData ? configData.text_color_secondary : "" }}>{currentUser && walletInfo ? walletInfo.balance : "0.00"}</span>
                                            </span>
                                            <a onClick={async (e) => {
                                                setLoading(true)
                                                setTimeout(() => {
                                                    setLoading(false)
                                                }, 500);
                                            }}>
                                                <img class="ml-1 mt-n2" style={{ height: "15px" }} src={require("../images/Profile/refresh.png")} />
                                            </a>
                                        </p>
                                        <div class="progress mb-3 profile-progressbar-outer">
                                            <div class="progress-bar profile-progressbar-bar" role="progressbar" style={{ width: progressCash ? `${progressCash}%` : "0%", backgroundColor: configData ? configData.secondary_color : "" }} aria-valuenow="34.5" aria-valuemin="0" aria-valuemax="100">
                                                {progressCash && (progressCash>100)?"100.00":progressCash}%
                                            </div>
                                        </div>
                                        <p class="my-1">
                                            <span style={{ fontSize: "1.2rem" }} class="profile-text">
                                                <span style={{ color: configData ? configData.text_color : "" }}>{t("Chips")}: </span>
                                                <span style={{ color: configData ? configData.text_color_secondary : "" }}>{currentUser && walletInfo ? walletInfo.chips_balance : "0.00"}</span>

                                            </span>
                                            <a onClick={async (e) => {
                                                setLoading(true)
                                                setTimeout(() => {
                                                    setLoading(false)
                                                }, 500);
                                            }}>
                                                <img class="ml-1 mt-n2" style={{ height: "15px" }} src={require("../images/Profile/refresh.png")} />
                                            </a>
                                        </p>
                                        <div class="progress mb-3 profile-progressbar-outer">
                                            <div class="progress-bar profile-progressbar-bar" role="progressbar" style={{ width: progressChip ? `${progressChip}%` : "0%", backgroundColor: configData ? configData.secondary_color : "" }} aria-valuenow="34.5" aria-valuemin="0" aria-valuemax="100">
                                                {progressChip && (progressChip>= 100)?"100.00":progressChip}%
                                            </div>
                                        </div>
                                        <p class="mb-1" style={{ color: configData ? configData.text_color : "" }}>
                                            {t("Min")} {t("Deposit")}: &nbsp; {turnOver && turnOver.min_deposit ? turnOver.min_deposit.toFixed(2) : 0}
                                            {/* <span style={{ fontWeight: "bold", color: configData ? configData.text_color_secondary : "" }} class="profile-text">RM 10.00</span> */}
                                        </p>
                                        <p class="mb-3" style={{ color: configData ? configData.text_color : "" }}>
                                            {t("Min")} {t("Withdrawal")}: &nbsp; {turnOver && turnOver.min_turnover ? turnOver.min_turnover.toFixed(2) : 0}
                                            {/* Mininum Withdrawal: <span style={{ fontWeight: "bold", color: configData ? configData.text_color_secondary : "" }} class="profile-text">RM 285.00</span> */}
                                        </p>
                                        <div>
                                            <a class="btn btn-block btn-1" style={{ backgroundColor: configData ? configData.secondary_color : "" }} href="/#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    navigate("/deposit");
                                                }}
                                            >
                                                Deposit Now
                                            </a>
                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex m-2 flex-wrap" style={{ fontSize: "0.8rem", paddingBottom: "80px" }}>
                                    <div class="col-4 square" onClick={(e) => {
                                        e.preventDefault();
                                        navigate(configData && configData.profile_tab_route_1 ? "/" + configData.profile_tab_route_1 : "/referral-system");
                                    }}>
                                        <a class="text-decoration-none d-flex flex-column justify-content-center align-items-center" href="/#">
                                            {configData && configData.profile_tab_icon_1 ? <img src={configData.profile_tab_icon_1} alt="" width="50px" height="50px" style={{ objectFit: "contain" }} /> : <i style={{ fontSize: "50px", color: configData ? configData.text_color : "" }} className="bi-people-fill"></i>}
                                            <p class="m-0 pt-2 text-center">{configData && configData.profile_tab_text_1 ? configData.profile_tab_text_1 : t("Referral_system")}</p>
                                        </a>
                                    </div>
                                    <div class="col-4 square" onClick={(e) => {
                                        e.preventDefault();
                                        navigate(configData && configData.profile_tab_route_2 ? "/" + configData.profile_tab_route_2 : "/app-accounts");
                                    }}>
                                        <a class="text-decoration-none d-flex flex-column justify-content-center align-items-center" href="/#">
                                            {configData && configData.profile_tab_icon_2 ? <img src={configData.profile_tab_icon_2} alt="" width="50px" height="50px" style={{ objectFit: "contain" }} /> : <i style={{ fontSize: "50px", color: configData ? configData.text_color : "" }} className="bi-controller"></i>}
                                            <p class="m-0 pt-2 text-center">{configData && configData.profile_tab_text_2 ? configData.profile_tab_text_2 : t("Game_Accounts")}</p>
                                        </a>
                                    </div>
                                    <div class="col-4 square" onClick={(e) => {
                                        e.preventDefault();
                                        navigate(configData && configData.profile_tab_route_3 ? "/" + configData.profile_tab_route_3 : "/bank-accounts");
                                    }}>
                                        <a class="text-decoration-none d-flex flex-column justify-content-center align-items-center" href="/#">
                                            {configData && configData.profile_tab_icon_3 ? <img src={configData.profile_tab_icon_3} alt="" width="50px" height="50px" style={{ objectFit: "contain" }} /> : <i style={{ fontSize: "50px", color: configData ? configData.text_color : "" }} className="bi-bank"></i>}
                                            <p class="m-0 pt-2 text-center">{configData && configData.profile_tab_text_3 ? configData.profile_tab_text_3 : t("Bank_Accounts")}</p>
                                        </a>
                                    </div>
                                    <div class="col-4 square" onClick={(e) => {
                                        e.preventDefault();
                                        navigate(configData && configData.profile_tab_route_4 ? "/" + configData.profile_tab_route_4 : "/deposit");
                                    }}>
                                        <a class="text-decoration-none d-flex flex-column justify-content-center align-items-center" href="/#">
                                            {configData && configData.profile_tab_icon_4 ? <img src={configData.profile_tab_icon_4} alt="" width="50px" height="50px" style={{ objectFit: "contain" }} /> : <i style={{ fontSize: "50px", color: configData ? configData.text_color : "" }} className="bi-cash-coin"></i>}
                                            <p class="m-0 pt-2 text-center">{configData && configData.profile_tab_text_4 ? configData.profile_tab_text_4 : t("Deposit")}</p>
                                        </a>
                                    </div>
                                    <div class="col-4 square" onClick={(e) => {
                                        e.preventDefault();
                                        navigate(configData && configData.profile_tab_route_5 ? "/" + configData.profile_tab_route_5 : "/withdraw");
                                    }}>
                                        <a class="text-decoration-none d-flex flex-column justify-content-center align-items-center" href="/#">
                                            {configData && configData.profile_tab_icon_5 ? <img src={configData.profile_tab_icon_5} alt="" width="50px" height="50px" style={{ objectFit: "contain" }} /> : <i style={{ fontSize: "50px", color: configData ? configData.text_color : "" }} className="bi-credit-card"></i>}
                                            <p class="m-0 pt-2 text-center">{configData && configData.profile_tab_text_5 ? configData.profile_tab_text_5 : t("Withdrawal")}</p>
                                        </a>
                                    </div>
                                    <div class="col-4 square" onClick={(e) => {
                                        e.preventDefault();
                                        navigate(configData && configData.profile_tab_route_6 ? "/" + configData.profile_tab_route_6 : "/history");
                                    }}>
                                        <a class="text-decoration-none d-flex flex-column justify-content-center align-items-center" href="/#">
                                            {configData && configData.profile_tab_icon_6 ? <img src={configData.profile_tab_icon_6} alt="" width="50px" height="50px" style={{ objectFit: "contain" }} /> : <i style={{ fontSize: "50px", color: configData ? configData.text_color : "" }} className="bi-clock-history"></i>}
                                            <p class="m-0 pt-2 text-center">{configData && configData.profile_tab_text_6 ? configData.profile_tab_text_6 : t("Orders_History")}</p>
                                        </a>
                                    </div>
                                    <div class="col-4 square" onClick={(e) => {
                                        e.preventDefault();
                                        navigate(configData && configData.profile_tab_route_7 ? "/" + configData.profile_tab_route_7 : "/bet-history");
                                    }}>
                                        <a class="text-decoration-none d-flex flex-column justify-content-center align-items-center" href="/#">
                                            {configData && configData.profile_tab_icon_7 ? <img src={configData.profile_tab_icon_7} alt="" width="50px" height="50px" style={{ objectFit: "contain" }} /> : <i style={{ fontSize: "50px", color: configData ? configData.text_color : "" }} className="bi-joystick"></i>}
                                            <p class="m-0 pt-2 text-center">{configData && configData.profile_tab_text_7 ? configData.profile_tab_text_7 : t("Bet_History")}</p>
                                        </a>
                                    </div>

                                    <div class="col-4 square" onClick={(e) => {
                                        e.preventDefault();
                                        navigate(configData && configData.profile_tab_route_8 ? "/" + configData.profile_tab_route_8 : "/change-password");
                                    }}>
                                        <a class="text-decoration-none d-flex flex-column justify-content-center align-items-center" href="/#">
                                            {configData && configData.profile_tab_icon_8 ? <img src={configData.profile_tab_icon_8} alt="" width="50px" height="50px" style={{ objectFit: "contain" }} /> : <i style={{ fontSize: "50px", color: configData ? configData.text_color : "" }} className="bi-question-circle"></i>}
                                            <p class="m-0 pt-2 text-center">{configData && configData.profile_tab_text_8 ? configData.profile_tab_text_8 : t("Change_Password")}</p>
                                        </a>
                                    </div>
                                    <div class="col-4 square" onClick={(e) => {
                                        e.preventDefault();
                                        navigate(configData && configData.profile_tab_route_9 ? "/" + configData.profile_tab_route_9 : "/vip-ranking");
                                    }}>
                                        <a class="text-decoration-none d-flex flex-column justify-content-center align-items-center" href="/#">
                                            {configData && configData.profile_tab_icon_9 ? <img src={configData.profile_tab_icon_9} alt="" width="50px" height="50px" style={{ objectFit: "contain" }} /> : <i style={{ fontSize: "50px", color: configData ? configData.text_color : "" }} className="bi-people"></i>}
                                            <p class="m-0 pt-2 text-center">{configData && configData.profile_tab_text_9 ? configData.profile_tab_text_9 : t("VIP_Ranking")}</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                        <MenuNotification />
                        <MenuSidebar />
                    </div>
                    {isLoading && <Loading />}
                </div>
                :null
            }
            {
                (configData && (configData.profile_style == "2"))?
                <Profile2 
                    getWalletInfo ={getWalletInfo}
                    setAuth={setAuth} 
                    walletInfo={walletInfo}
                />
                :null
            }
            {
                (configData && (configData.profile_style == "3"))?
                <Profile3 
                    getWalletInfo ={getWalletInfo}
                    setAuth={setAuth} 
                    walletInfo={walletInfo}
                />
                :null
            }
        </>
    )
}

export default Profile