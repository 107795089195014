import api, { PublicAxios, OpenAxios } from './api';
import TokenService from "./token.service";

class UserService {
  getConfigs() {
    return PublicAxios.get('/skinconfig/');
  }

  getClickToClaims() {
    return api.get('/clicktoclaims/');
  }

  getPromotionGroups() {
    return api.get('/promotiongroups/');
  }

  claimCTCPromotion(id) {
    return api.post(`/clicktoclaims/`,{
      id
    });
  }

  getSliderImages() {
    return PublicAxios.get('/notices/?active');
  }

  getTablist() {
    return PublicAxios.get('/products/categories/');
  }

  getProducts(category) {
    return PublicAxios.get(`/products/?category=${category}`);
  }

  getProductsDirect() {
    return PublicAxios.get(`/products/?direct=true`);
  }

  getProductsDirectGameList(category, id) {
    return PublicAxios.get(`/products/?category=${category}&id=${id}&direct=true`);
  }

  getProductsDirectGameListByName(category, name) {
    return PublicAxios.get(`/products/?category=${category}&name=${name}`);
  }

  getFeaturedProducts() {
    return PublicAxios.get(`/products/?featured=true`);
  }

  getEztechProducts(start, end) {
    return api.get(`/product/eztech88/games/?_start=${start}&_end=${end}`)
  }

  getJokerProducts(start, end) {
    return api.get(`/product/joker/games/?_start=${start}&_end=${end}`)
  }

  getHabaneroProducts(start, end) {
    return api.get(`/product/habanero/games/?_start=${start}&_end=${end}`)
  }

  getPlaytechProducts(start, end) {
    return api.get(`/product/playtech/games/?_start=${start}&_end=${end}`)
  }

  getJDBProducts(start, end) {
    return api.get(`/product/jdb/games/?_start=${start}&_end=${end}`)
  }

  getSpadeGamingProducts(start, end) {
    return api.get(`/product/spadegaming/games/?_start=${start}&_end=${end}`)
  }

  getLive22Products(start, end) {
    return api.get(`/product/live22/games/?_start=${start}&_end=${end}`)
  }

  getPragmaticProducts(start, end) {
    return api.get(`/product/pragmatic/games/?_start=${start}&_end=${end}`)
  }

  getVpowerProducts(start, end) {
    return api.get(`/product/vpower/games/?_start=${start}&_end=${end}`)
  }

  getJiliProducts(start, end) {
    return api.get(`/product/jili/games/?_start=${start}&_end=${end}`)
  }

  getEvo888h5Products(start, end) {
    return api.get(`/product/evo888h5/games/?_start=${start}&_end=${end}`)
  }

  getOtherProducts(start, end, product_name) {
    return api.get(`/product/${product_name.toLowerCase()}/games/?_start=${start}&_end=${end}`)
  }

  searchGames(query, game) {
    return api.get(`/product/${game.toLowerCase()}/games/?search=${query}`)
  }

  getStartedGames(id) {
    return api.get(`/user-game-account/${id}/`)
  }

  getTurnover() {
    const token = TokenService.getLocalAccessToken();
    if(token){
      return api.get(`/wallet/min_deposit_and_min_turnover/`)
    }
  }

  getNewsfeeds() {
    return api.get('/newsfeeds/');
  }

  getBalance() {
    const token = TokenService.getLocalAccessToken();
    if(token){
      return api.get('/wallet/');
    }
  }

  getNotificationCount() {
    return api.get('/notifications/count/');
  }

  getPromotions() {
    return api.get('/promotions/');
  }

  getPromotionsTabs() {
    return api.get('/promotions/promo_tabs/');
  }

  getPromotionsDepositList() {
    return api.get('/promotions/deposit_promotion_list/');
  }

  getBanks() {
    return OpenAxios.get('/banks/');
  }

  getBankDetails(bankId) {
    return OpenAxios.get(`/banks/${bankId}/`)
  }

  getMerchantBanks() {
    return PublicAxios.get('/merchant-bank-accounts/');
  }

  getMerchantBanksWithRank(rankID) {
    return PublicAxios.get(`/merchant-bank-accounts?ranks=${rankID}`);
  }

  getMerchantEWallets(ewallet_provider = null) {
    return PublicAxios.get(`/merchant-ewallet-accounts?ewallet_provider=${ewallet_provider}`);
  }

  // getBankDetails(bankId) {
  //   return PublicAxios.get(`/banks/${bankId}`)
  // }

  getCustomBanks() {
    const token = TokenService.getLocalAccessToken();
    if(token){
      return api.get('/customer-bank-accounts/');
    }
  }

  getCouponTypelist() {
    return api.get('/coupon-types/');
  }

  createDeposit(values) {
    return api.post('/deposits/', values);
  }

  withdraw(values) {
    return api.post('/withdrawals/', values);
  }

  startProduct(id, selectedCredit) {
    return api.post(`/product/${id}/start/?credit_type=${selectedCredit}`);
  }

  changeGameId(id) {
    return api.put(`/changegameid/${id}`);
  }

  stopProduct(id) {
    return api.post(`/product/${id}/stop/`);
  }
  stopGames(id) {
    const token = TokenService.getLocalAccessToken();
    if(token){
      return api.post(`/games/stop/`);
    }
  }

  startEasytechProduct(id, selectedCredit) {
    return api.post(`/product/eztech88/start/${id}/?credit_type=${selectedCredit}`);
  }

  startEasytogoProduct(id, gameid, selectedCredit) {
    return api.post(`/product/easytogo/start/${id}/${gameid}/?credit_type=${selectedCredit}`);
  }

  checkProduct(id) {
    return api.get(`/product/${id}/game-account/`)
  }

  getOrders(id) {
    return api.get(`/actors/transactions/`)
  }

  getTransfers(id) {
    return api.get(`/transfers/`)
  }

  getChips(id) {
    return api.get(`/chips/`)
  }

  getRewards() {
    return api.get(`/rewards/`)
  }

  getBonues(id) {
    return api.get(`/bonuses/`)
  }

  deleteActor(userId, id) {
    return api.delete(`/actors/transactions/${id}/`)
  }

  deleteChipTx(id) {
    return api.delete(`/chips/${id}/`)
  }

  getAppAccounts() {
    return api.get(`/game-accounts/?ltype=app`)
  }

  getBetHistory(userId) {
    return api.get(`/user-bet-history/`, userId)
  }

  getDownline(start,end) {
    return api.get(`/downlines/players/`)
  }

  getCommissions(from,to,start,end) {
    if (from && to) {
      return api.get(`/commissions/by_date_range?from=${from}&to=${to}&_start=${start}&_end=${end}`)
    } else {
      return api.get(`/commissions/by_player/?_start=${start}&_end=${end}`)
    }
  }

  getCommissionsSingleMultilevel(from,to,start,end) {
    if (from && to) {
      return api.get(`/commissions/by_player_combine_single_multilevel_by_date_range?from=${from}&to=${to}&_start=${start}&_end=${end}`)
    } else {
      return api.get(`/commissions/by_player_combine_single_multilevel/?_start=${start}&_end=${end}`)
    }
  }

  getLaporan(start,end) {
    return api.get(`/commissions/by_player/?_start=${start}&_end=${end}`)
  }

  addCustomerBank(values) {
    return api.post('/customer-bank-accounts/', values)
  }
  getSocials() {
    return api.get('/socials/')
  }
  getLastDeposits() {
    return api.get('/last-transfers/deposits/')
  }
  getLastWithdrawals() {
    return api.get('/last-transfers/withdrawals/')
  }
  getDailyCheckins() {
    return api.get('/daily-checkins/')
  }
  claimTodayCheckin() {
    return api.post('/daily-checkins/')
  }
  getDailyCheckinsV2() {
    return api.get('/daily-checkins/v2/')
  }
  claimTodayCheckinV2() {
    return api.post('/daily-checkins/v2/')
  }
  getGlobalSettings() {
    return api.get('/globalsettings/')
  }

  getTokens() {
    return api.get('/daily-checkins/v2/get_tokens/')
  }
  getLuckyWheelSlots() {
    return api.get('/luckywheel/')
  }
  getMail() {
    return api.get(`/mail/`)
  }
  updateIsRead(id, payload) {
    return api.put(`/mail/${id}/`, payload)
  }
  spinLuckyWheel() {
    return api.get('/luckywheel/spin/')
  }
  getDailyRebate() {
    return api.get('/rewards/dailyrebate/')
  }
  claimDailyRebate(id) {
    return api.post('/rewards/dailyrebate/', {
      id
    })
  }
}

export default new UserService();
