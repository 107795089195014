import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../service/auth.service";
import userService from "../service/user.service";
import Loading from "./Loading";
import { ConfigContext } from "../ConfigContext";
import { useTranslation } from "react-i18next";

const BalanceSectionAuth4 =
({  
  getWalletInfo,
  walletInfo,
  StopProduct
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [turnOver, setTurnOver] = useState(null)
  const configData = useContext(ConfigContext)
  const [rank, setRank] = useState(null)
  const [currentUser, setCurrentUser] = useState(null);
  const currency = configData && configData.currency;
  const [startedGames, setStartedGames] = useState([]);
  const [bank, setBank] = useState(null);
  const [progressCash, setProgressCash] = useState(null)
  {/* Site Name for demo purpose, will have to remove after demo */}
  const SITE_NAME = process.env.REACT_APP_SITE_NAME;
  const fetchBanks = async () => {
    try {
      // fetch customer bank details
      const customerBanks = await userService.getCustomBanks();
      const customerBank = customerBanks.data;
      if (customerBank.length === 0) {
        return;
      }

      let banks = [];
      customerBank.map((bank) =>
        banks.push({
          ...bank,
          metaData: {
            name: "",
          },
        })
      );

      const bank = customerBank[0];
      const bankData = await userService.getBankDetails(bank.bank);
      if (bankData.status === 200) {
        setBank({
          ...bank,
          metaData: bankData.data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchBanks();
  }, []);
  useEffect(() => {
    const fetchWalletInfo = async () => {
      const user = AuthService.getCurrentUser();
      if (user) {
        await getWalletInfo();
        setCurrentUser(user);
      }
    };
    fetchWalletInfo();
  }, [localStorage.getItem("user")]);

  const getStartedGames = async (userId) => {
    try {
      const startedProducts = await userService.getStartedGames(userId);
      if (startedProducts.data) {
        setStartedGames(startedProducts.data);
      }
    } catch (error) {
      setStartedGames([]);
    }
  };

  const getTurnover = async () => {
    try {
      const turnover = await userService.getTurnover();
      if (turnover.data && turnover.data.length) {
        setTurnOver(turnover.data[0])
      }
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    if (walletInfo && walletInfo.user && walletInfo.user.id) {
      getStartedGames(walletInfo.user.id);
    }
    if(walletInfo && walletInfo.rank){
      const userRank = walletInfo.rank.split(" ")
      if(userRank[1]){
        setRank(userRank[1])
      }
    }
    getTurnover()
  }, [walletInfo]);
  useEffect(() => {
    if (walletInfo && walletInfo.balance && turnOver && turnOver.min_turnover) {
      const balance = parseFloat(walletInfo.balance).toFixed(2);
      const min_turnover = parseFloat(turnOver.min_turnover).toFixed(2);
      const progressCashRate = ((balance / min_turnover)*100).toFixed(2);
      setProgressCash(progressCashRate);
    }
  }, [turnOver, walletInfo]);
  const checkBg = (configData, type) => {
    // console.log("val", val)
    if (!configData) {
      return {}
    }
    if (configData[configData[type]][0] === "#" || configData[configData[type]].includes("rgb") || configData[configData[type]].includes("hsl")) {
      return {backgroundColor: configData[configData[type]]}
    }
    return {backgroundImage: `url(${configData[configData[type]]})`}
  }

  return (
    <div className="balance_wrapper">
    {/* Site Name for demo purpose, will have to remove after demo */}
    {(!currentUser && SITE_NAME !== "NNNN8888") && (
      <div>
        <div className="login-container w-full">
          <div className="left-column">
            <div className="login-btn" onClick={() => navigate("/signin")}>
              <img
                src={configData?configData.home_login_btn :""}
                className="login-image"
                alt=""
              />
            </div>
          </div>
          <div className="right-column">
            <div className="register-btn" onClick={() => navigate("/signup")}>
              <img
                src={configData?configData.home_register_btn :""}
                className="register-image"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    )}
      <div style={{ 
        borderRadius: "1rem", 
        ...checkBg(configData, 
        "wallet_container_bg_type"),
        border: `2px solid ${configData && configData.border_color}`, 
        boxShadow: `0 0 12px ${configData && configData.border_shadow_primary_color}, 0 0 12px ${configData && configData.border_shadow_primary_color} inset`, 
        marginBottom: "15px",
        }} className="balance-container w-full">
        <div className="balance-context w-full flex-col">
          <div className="d-flex align-items-center justify-content-between w-100">
          <div className="left-column">
            <div class="auth4-balance-container">
              <div class="auth4-balance-item">
                <h4 class="t1">Balance:</h4>
                <h1 class="vc-balance" style={{
                  color: "gold",
                  fontWeight: "700",
                }}>{currency} {currentUser && walletInfo ? walletInfo.balance : "0.00"}</h1>
              </div>
              {/* <div class="auth4-balance-item">
                <h4 class="t1">Chips:</h4>
                <h1 class="vc-balance" style={{
                  color: "gold",
                  fontWeight: "700",
                }}>{currency} {currentUser && walletInfo ? walletInfo.chips_balance : "0.00"}</h1>
              </div> */}
            </div>
              <div class="note" style={{ marginBottom: "10px"}}>
                <p style={{ color: "white" }}><b><var>{t("Min")} {t("Deposit")}: &nbsp; {currency} {turnOver && turnOver.min_deposit ? turnOver.min_deposit.toFixed(2) : '10.00'} </var></b></p>
                <p style={{ color: "white" }}><b><var>{t("Min")} {t("Withdrawal")}: &nbsp; {currency} {turnOver && turnOver.min_turnover ? turnOver.min_turnover.toFixed(2) : '80.00'} </var></b></p>
                <p style={{ color: "white" }}><b><var>{t("Max")} {t("Withdrawal")}: &nbsp; {currency} 50000.00 </var></b></p>
              </div>
          </div>

          <div className="right-column h-full d-flex" style={{flex: 1.5}}>
            <div className="balance_btns flex-column" style={{marginLeft: "auto"}}>
              <img
                src={configData ? configData.wallet_deposit : ""}
                alt="Deposit"
                onClick={(e) => {
                  navigate("/deposit");
                }}
              />

              <img
                src={configData ? configData.wallet_withdraw : ""}
                alt="Withdraw"
                onClick={(e) => {
                  navigate("/withdraw");
                }}
              />
              <img
                src={configData ? configData.wallet_refresh : ""}
                alt="Refresh"
                onClick={async (e) => {
                  setLoading(true)
                  startedGames.forEach(startedGame => {
                    StopProduct(startedGame.id)
                  })
                  setTimeout(() => {
                    setLoading(false)
                  }, 500);
                }}
              />
            </div>
          </div>
          </div>
          <div class="w-100 px-1 pt-1 profile-winover" style={{boxShadow:"none", border:"none",borderRadius:"0px", borderTop: "2px solid #E6960C"}}>
          <p class="my-1 text-center" style={{ color: configData ? configData.text_color : "" }}>
              {t("Bonus_Winover_Requirement")}: <br />
              <span style={{ fontSize: "1.2rem" }} class="profile-text">
                   <span style={{ color: configData ? configData.text_color : "" }}>{t("Cash")}: </span>
                   <span style={{ color: configData ? configData.text_color_secondary : "" }}>{currentUser && turnOver ? turnOver.min_turnover : "0.00"}</span>
              </span>
              <a onClick={async (e) => {
                  setLoading(true)
                  setTimeout(() => {
                      setLoading(false)
                  }, 500);
              }}>
                  <img class="ml-1 mt-n2" style={{ height: "15px" }} src={require("../images/Profile/refresh.png")} />
              </a>
          </p>
          <div class="progress mb-3 profile-progressbar-outer">
              <div class="progress-bar profile-progressbar-bar" role="progressbar" style={{ width: progressCash ? `${progressCash}%` : "0%", backgroundColor: configData ? configData.secondary_color : "" }} aria-valuenow="34.5" aria-valuemin="0" aria-valuemax="100">
                  {progressCash && (progressCash>100)?"100.00":progressCash}%
              </div>
          </div>
      </div>
        </div>
      </div>
      <div>
    </div>
    {isLoading && <Loading />}
    </div>
  );
}

export default BalanceSectionAuth4;