import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../service/auth.service";
import UserService from "../service/user.service";
import Loading from "./Loading";
import { ConfigContext } from "../ConfigContext";
import { useTranslation } from "react-i18next";

export default function BalanceSectionNew({
  getWalletInfo,
  walletInfo,
  StopProduct,
  bank,
}) {
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState(null);
  const [startedGames, setStartedGames] = useState([]);
  // const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [turnOver, setTurnOver] = useState(null)
  const configData = useContext(ConfigContext)
  const [progressCash, setProgressCash] = useState(null)


  const getNotificationInfo = async () => {
    // const getNotification = await UserService.getNotificationCount();
    // setCount(getNotification.data.count);
  };

  useEffect(() => {
    const fetchWalletInfo = async () => {
      const user = AuthService.getCurrentUser();
      if (user) {
        await getWalletInfo();
        setCurrentUser(user);
        getNotificationInfo();
      }
    };
    fetchWalletInfo();
  }, [localStorage.getItem("user")]);
  const getStartedGames = async (userId) => {
    try {
      const startedProducts = await UserService.getStartedGames(userId);
      if (startedProducts.data) {
        setStartedGames(startedProducts.data);
      }
    } catch (error) {
      setStartedGames([]);
    }
  };

  const getTurnover = async () => {
    try {
      const turnover = await UserService.getTurnover();
      if (turnover.data && turnover.data.length) {
        setTurnOver(turnover.data[0])
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (walletInfo && walletInfo.balance && turnOver && turnOver.min_turnover) {
      const balance = parseFloat(walletInfo.balance).toFixed(2);
      // const chips_balance = parseFloat(walletInfo.chips_balance).toFixed(2);
      const min_turnover = parseFloat(turnOver.min_turnover).toFixed(2);
      const progressCashRate = ((balance / min_turnover)*100).toFixed(2);
      // const progressChipRate = ((chips_balance / min_turnover)*100).toFixed(2);
  
      setProgressCash(progressCashRate);
      // setProgressChip(progressChipRate);
    //   if (progressWithdrawRate >= 1) {
    //   } else {
    //     const progressWithdrawRatePercent = (progressWithdrawRate * 100).toFixed(2);
    //     // setProgressWithdraw(`${progressWithdrawRatePercent}%`);
    //   }
    }
  }, [turnOver, walletInfo]);


  useEffect(() => {
    if (walletInfo && walletInfo.user && walletInfo.user.id) {
      getStartedGames(walletInfo.user.id);
    }
    getTurnover()
  }, [walletInfo]);

  // const checkBg = (val) => {
  //   if (val && (val[0] === "#" || val.includes("rgb") || val.includes("hsl"))) {
  //     return val
  //   }
  //   return `url(${val})`

  //   // if(val.includes("http") || val.includes(".png") || val.includes(".jpeg") || val.includes("jpg") || val.includes("svg")){
  //   //   return `url(${val})`
  //   // }
  //   // return val;
  // }
  const checkBg = (configData, type) => {
    if (!configData) {
      return {}
    }
    if (configData[configData[type]][0] === "#" || configData[configData[type]].includes("rgb") || configData[configData[type]].includes("hsl")) {
      return {backgroundColor: configData[configData[type]]}
    }
    return {backgroundImage: `url(${configData[configData[type]]})`}
  }

  return (
    <div style={{borderRadius:"0px 1rem 1rem 0px", ...checkBg(configData, "wallet_container_bg_type") }} className="balance-container w-full d-flex flex-column">
      <div className="balance-context w-full">
        <div className="right-column h-full d-flex w-full">
          <div className="balance_btns d-flex w-full" style={{marginLeft: "auto"}}>
            <div className="balance-img_container cursor-pointer" role="button">
              <img
                style={{width: "50px"}}
                src={configData ? configData.wallet_deposit : ""}
                alt="Deposit"
                onClick={(e) => {
                  navigate("/deposit");
                }}
              />
              <p className="text-center" style={{color: "white", fontWeight: "bold"}}>Deposit</p>
            </div>
            <div className="balance-img_container cursor-pointer" role="button">
              <img
                style={{width: "50px"}}
                src={configData ? configData.wallet_withdraw : ""}
                alt="Withdraw"
                onClick={(e) => {
                  navigate("/withdraw");
                }}
              />
              <p className="text-center" style={{color: "white", fontWeight: "bold"}}>Withdraw</p>
            </div>
            <div className="balance-img_container cursor-pointer" role="button">
              <img
                style={{width: "50px"}}
                src={configData ? configData.wallet_refresh : ""}
                alt="Refresh"
                onClick={async (e) => {
                  setLoading(true)
                  startedGames.forEach(startedGame => {
                    StopProduct(startedGame.id)
                  })
                  setTimeout(() => {
                    setLoading(false)
                  }, 500);
                }}
              />
              <p className="text-center" style={{color: "white", fontWeight: "bold"}}>Refresh</p>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="w-100 px-1 pt-1 profile-winover" style={{boxShadow:"none", border:"none",borderRadius:"0px", borderTop: "2px solid #E6960C"}}>
          <p class="my-1" style={{ color: configData ? configData.text_color : "" }}>
              {t("Bonus_Winover_Requirement")}: <br />
              <span style={{ fontSize: "1.2rem" }} class="profile-text">
                   <span style={{ color: configData ? configData.text_color : "" }}>{t("Cash")}: </span>
                   <span style={{ color: configData ? configData.text_color_secondary : "" }}>{currentUser && walletInfo ? walletInfo.balance : "0.00"}</span>
              </span>
              <a onClick={async (e) => {
                  setLoading(true)
                  setTimeout(() => {
                      setLoading(false)
                  }, 500);
              }}>
                  <img class="ml-1 mt-n2" style={{ height: "15px" }} src={require("../images/Profile/refresh.png")} />
              </a>
          </p>
          <div class="progress mb-3 profile-progressbar-outer">
              <div class="progress-bar profile-progressbar-bar" role="progressbar" style={{ width: progressCash ? `${progressCash}%` : "0%", backgroundColor: configData ? configData.secondary_color : "" }} aria-valuenow="34.5" aria-valuemin="0" aria-valuemax="100">
                  {progressCash && (progressCash>100)?"100.00":progressCash}%
              </div>
          </div>
      </div> */}
      {/* <div className="d-flex mt-auto justify-content-between">
        <div className="min-deposit text-center">
          {t("Min")} {t("Deposit")}: &nbsp; {turnOver && turnOver.min_deposit ? turnOver.min_deposit.toFixed(2) : 0}
        </div>
        <div className="min-withdrawl text-center">
          {t("Min")} {t("Withdrawal")}: &nbsp; {turnOver && turnOver.min_turnover ? turnOver.min_turnover.toFixed(2) : 0}
        </div>
      </div> */}
      {isLoading && <Loading />}
    </div>
  );
}
