import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../service/auth.service";
import UserService from "../service/user.service";
import Loading from "./Loading";
import { ConfigContext } from "../ConfigContext";
import { useTranslation } from "react-i18next";

export default function BalanceSection({
  getWalletInfo,
  walletInfo,
  StopProduct,
  bank,
}) {
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState(null);
  const [startedGames, setStartedGames] = useState([]);
  // const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [turnOver, setTurnOver] = useState(null)
  const [rank, setRank] = useState(null)
  const [progressCash, setProgressCash] = useState(null)
  const configData = useContext(ConfigContext)


  const getNotificationInfo = async () => {
    // const getNotification = await UserService.getNotificationCount();
    // setCount(getNotification.data.count);
  };

  useEffect(() => {
    const fetchWalletInfo = async () => {
      const user = AuthService.getCurrentUser();
      if (user) {
        await getWalletInfo();
        setCurrentUser(user);
        getNotificationInfo();
      }
    };
    fetchWalletInfo();
  }, [localStorage.getItem("user")]);

  const getStartedGames = async (userId) => {
    try {
      const startedProducts = await UserService.getStartedGames(userId);
      if (startedProducts.data) {
        setStartedGames(startedProducts.data);
      }
    } catch (error) {
      setStartedGames([]);
    }
  };

  const getTurnover = async () => {
    try {
      const turnover = await UserService.getTurnover();
      if (turnover.data && turnover.data.length) {
        setTurnOver(turnover.data[0])
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (walletInfo && walletInfo.balance && turnOver && turnOver.min_turnover) {
      const balance = parseFloat(walletInfo.balance).toFixed(2);
      const min_turnover = parseFloat(turnOver.min_turnover).toFixed(2);
      const progressCashRate = ((balance / min_turnover)*100).toFixed(2);
      setProgressCash(progressCashRate);
    }
  }, [turnOver, walletInfo]);


  useEffect(() => {
    if (walletInfo && walletInfo.user && walletInfo.user.id) {
      getStartedGames(walletInfo.user.id);
    }
    if(walletInfo && walletInfo.rank){
      const userRank = walletInfo.rank.split(" ")
      if(userRank[1]){
        setRank(userRank[1])
      }
    }
    getTurnover()
  }, [walletInfo]);

  // const checkBg = (val) => {
  //   if (val && (val[0] === "#" || val.includes("rgb") || val.includes("hsl"))) {
  //     return val
  //   }
  //   return `url(${val})`

  //   // if(val.includes("http") || val.includes(".png") || val.includes(".jpeg") || val.includes("jpg") || val.includes("svg")){
  //   //   return `url(${val})`
  //   // } 
  //   // return val;
  // }
  const checkBg = (configData, type) => {
    // console.log("val", val)
    if (!configData) {
      return {}
    }
    if (configData[configData[type]][0] === "#" || configData[configData[type]].includes("rgb") || configData[configData[type]].includes("hsl")) {
      return {backgroundColor: configData[configData[type]]}
    }
    return {backgroundImage: `url(${configData[configData[type]]})`}
  }

  return (
    <div style={{ borderRadius: "1rem", ...checkBg(configData, "wallet_container_bg_type"),border: "2px solid #E6960C", boxShadow: "0 0 12px rgba(230, 150, 12, 0.8), 0 0 12px rgba(230, 150, 12, 0.8) inset" }} className="balance-container w-full">
      <div className="balance-welcome d-flex justify-content-between flex-row px-4" style={{ ...checkBg(configData, "wallet_welcome_bg_type")}}>
       <div>
       <p className="balance-welcome-title">{t("Welcome")}, {bank && bank.name}</p>
        <p className="balance-welcome-subtitle">
          {bank && bank.metaData.name} ({bank && bank.number})
        </p>
       </div>
       {rank ? 
       <div className="d-flex justify-content-center align-items-center">
        <img src={require("../images/trophy-icon.png")} alt="" width="30px" height="30px" />
        <p>{rank}</p>
       </div>
       : ""}
      </div>
      <div className="balance-context w-full">
        <div style={{ flex: 1 }} className="left-column">
          <div style={{ border: "2px solid #E6960C", borderRadius: "1rem" }} className="center">
            <div className="balance_game" style={{ color: configData ? configData.text_color : "" }}>{t("Cash")}:</div>
            <div className="balance_credit" style={{ color: configData ? configData.text_color_secondary : "" }}>
              {currentUser && walletInfo ? walletInfo.balance : "0.00"}
            </div>
          </div>
          <div style={{ border: "2px solid #E6960C", borderRadius: "1rem", marginTop: "5px" }} className="center">
            <div className="balance_game" style={{ color: configData ? configData.text_color : "" }}>{t("Chips")}:</div>
            <div className="balance_credit" style={{ color: configData ? configData.text_color_secondary : "" }}>
              {currentUser && walletInfo ? walletInfo.chips_balance : "0.00"}
            </div>
          </div>
          {/* <div>
            <div className="min-deposit text-center">
              {t("Min")} {t("Deposit")}: &nbsp; {turnOver && turnOver.min_deposit ? turnOver.min_deposit.toFixed(2) : 0}
            </div>
            <div className="min-withdrawl text-center">
              {t("Min")} {t("Withdrawal")}: &nbsp; {turnOver && turnOver.min_turnover ? turnOver.min_turnover.toFixed(2) : 0}
            </div>
          </div> */}
        </div>

        <div className="right-column h-full d-flex" style={{flex: 1.5}}>
          <div className="balance_btns flex-column" style={{marginLeft: "auto"}}>
            <img
              src={configData ? configData.wallet_deposit : ""}
              alt="Deposit"
              onClick={(e) => {
                navigate("/deposit");
              }}
            />

            <img
              src={configData ? configData.wallet_withdraw : ""}
              alt="Withdraw"
              onClick={(e) => {
                navigate("/withdraw");
              }}
            />
            <img
              src={configData ? configData.wallet_refresh : ""}
              alt="Refresh"
              onClick={async (e) => {
                setLoading(true)
                startedGames.forEach(startedGame => {
                  StopProduct(startedGame.id)
                })
                setTimeout(() => {
                  setLoading(false)
                }, 500);
              }}
            />
          </div>
        </div>
      </div>
      <div class="w-100 px-1 pt-1 mt-2 profile-winover" style={{boxShadow:"none", border:"none",borderRadius:"0px", borderTop: "2px solid #E6960C", borderBottom: "2px solid #E6960C"}}>
          <p class="my-1 text-center" style={{ color: configData ? configData.text_color : "" }}>
            {t("Bonus_Winover_Requirement")}: <br />
            <span style={{ fontSize: "1.2rem" }} class="profile-text">
                <span style={{ color: configData ? configData.text_color : "" }}>{t("Cash")}: </span>
                <span style={{ color: configData ? configData.text_color_secondary : "" }}>{currentUser && turnOver ? turnOver.min_turnover : "0.00"}</span>
            </span>
            <a onClick={async (e) => {
                setLoading(true)
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            }}>
                <img class="ml-1 mt-n2" style={{ height: "15px" }} src={require("../images/Profile/refresh.png")} />
            </a>
          </p>
          <div class="progress mb-3 profile-progressbar-outer">
            <div class="progress-bar profile-progressbar-bar" role="progressbar" style={{ width: progressCash ? `${progressCash}%` : "0%", backgroundColor: configData ? configData.secondary_color : "" }} aria-valuenow="34.5" aria-valuemin="0" aria-valuemax="100">
                {progressCash && (progressCash>100)?"100.00":progressCash}%
            </div>
          </div>
      </div>
      <div>
            <div className="min-deposit text-center mt-2">
              {t("Min")} {t("Deposit")}: &nbsp; {turnOver && turnOver.min_deposit ? turnOver.min_deposit.toFixed(2) : 0}
            </div>
            <div className="min-withdrawl text-center">
              {t("Min")} {t("Withdrawal")}: &nbsp; {turnOver && turnOver.min_turnover ? turnOver.min_turnover.toFixed(2) : 0}
            </div>
          </div>
      {isLoading && <Loading />}
    </div>
  );
}
