import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../service/auth.service";
import { ConfigContext } from "../ConfigContext";
import { useTranslation } from "react-i18next";
import { countries } from "../i18n/languages";
import { LanguageContext } from "../i18n/LanguageContext";
import { useState } from "react";

function MenuSidebar({ currentUser, walletInfo, setCurrentUser, setAuth }) {
  const navigate = useNavigate();
  const configData = useContext(ConfigContext)
  const { language, setLanguage } = useContext(LanguageContext)
  const { t } = useTranslation();
  const [languageOpen, setLanguageOpen] = useState(false)
  const currency = configData && configData.currency;

  const checkBg = (val) => {
    if (val[0] === "#" || val.includes("rgb")) {
      return val
    }
    return `url(${val})`

    // if(val.includes("http") || val.includes(".png") || val.includes(".jpeg") || val.includes("jpg") || val.includes("svg")){
    //   return `url(${val})`
    // } 
    // return val;
  }

  return (
    <div
      id="menu-sidebar"
      data-menu-active="nav-welcome"
      className={`offcanvas offcanvas-detached d-block ${configData && (configData.sidebar_position == "right") ? "offcanvas-end" : "offcanvas-start"}`}
      style={{ background: checkBg(configData ? configData[configData.menusidebar_bg_type] : ""), }}
    >
      <div className="menu-size" style={{ width: "230px" }}>
        <div>
          {currentUser && (
            <div className="page-title custom_title sidebar-title d-flex mb-2">
              <div className="balance_game" style={{ fontSize: "28px", color: configData ? configData.text_color : "" }}>

                {walletInfo && walletInfo.username
                  ? walletInfo.username
                  : t("No_Account")}

                <div className="balance_credit" style={{ fontSize: "24px", marginTop: "5px", color: configData ? configData.text_color_secondary : "" }}>
                  {currency} {walletInfo && walletInfo.balance ? walletInfo.balance : "0"}
                </div>
              </div>
            </div>
          )}

          <div className="divider divider-margins mb-3 opacity-50"></div>

          <div className="list-group list-custom list-menu-large">
            {
              !(configData && configData.side_menu_active_1) ? null : currentUser ? (
              <a
                href="/#"
                id="nav-welcome"
                className="list-group-item list-group-item-active"
                style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(configData && configData.side_menu_route_1 ? configData.side_menu_route_1 : "/");
                }}
              >
                {configData && configData.side_menu_icon_1 ? <img src={configData.side_menu_icon_1} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i className="bi-house-fill"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                <div style={{ color: configData ? configData.text_color : "" }}>{configData && configData.side_menu_text_1 ? configData.side_menu_text_1 : t("Home")}</div>
                <i className="bi bi-chevron-right"></i>
              </a>
            ) : (
              <a
                href="/#"
                id="nav-welcome"
                className="list-group-item list-group-item-active"
                style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(configData && configData.side_menu_route_1 ? configData.side_menu_route_1 : "/");
                }}
              >
                {configData && configData.side_menu_icon_1 ? <img src={configData.side_menu_icon_1} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i className="bi-house-fill"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                <div style={{ color: configData ? configData.text_color : "" }}>{configData && configData.side_menu_text_1 ? configData.side_menu_text_1 : t("Home")}</div>
                <i className="bi bi-chevron-right"></i>
              </a>
            )}
            {
              !(configData && configData.side_menu_active_2) ? null : currentUser ? (
              <a
                href="/#"
                id="nav-homes"
                className="list-group-item"
                style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(configData && configData.side_menu_route_2 ? configData.side_menu_route_2 : "/promotions");
                }}
              >
                {configData && configData.side_menu_icon_2 ? <img src={configData.side_menu_icon_2} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i className="bi-megaphone"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                <div style={{ color: configData ? configData.text_color : "" }}>{configData && configData.side_menu_text_2 ? configData.side_menu_text_2 : t("Promotions")}</div>
                <i className="bi bi-chevron-right"></i>
              </a>
            ) : (
              <a
                href="/#"
                id="nav-homes"
                className="list-group-item"
                style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/signin");
                }}
              >
                {configData && configData.side_menu_icon_2 ? <img src={configData.side_menu_icon_2} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i className="bi-megaphone"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                <div style={{ color: configData ? configData.text_color : "" }}>{configData && configData.side_menu_text_2 ? configData.side_menu_text_2 : t("Promotions")}</div>
                <i className="bi bi-chevron-right"></i>
              </a>
            )}
            {
              !(configData && configData.side_menu_active_3) ? null : currentUser ? (
              <a
                href="/#"
                id="nav-gameaccount
              s"
                data-submenu="sub1"
                className={`list-group-item`}
                style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(configData && configData.side_menu_route_3 ? configData.side_menu_route_3 : "/app-accounts");
                }}
              >
                {configData && configData.side_menu_icon_3 ? <img src={configData.side_menu_icon_3} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i className="bi-people"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                <div style={{ color: configData ? configData.text_color : "" }}>{configData && configData.side_menu_text_3 ? configData.side_menu_text_3 : t("Game_Accounts")}</div>
                <i className={`bi bi-chevron-right`}></i>
              </a>
            ) : (
              <a
                href="/#"
                id="nav-gameaccounts"
                data-submenu="sub1"
                className={`list-group-item`}
                style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/signin");
                }}
              >
                {configData && configData.side_menu_icon_3 ? <img src={configData.side_menu_icon_3} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i className="bi-people"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                <div style={{ color: configData ? configData.text_color : "" }}>{configData && configData.side_menu_text_3 ? configData.side_menu_text_3 : t("Game_Accounts")}</div>
                <i className={`bi bi-chevron-right`}></i>
              </a>
            )}
            {
              
              !(configData && configData.side_menu_active_4) ? null : currentUser ? (
                <a
                  href="/#"
                  id="nav-pages"

                  className="list-group-item"
                  style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(configData && configData.side_menu_route_4 ? configData.side_menu_route_4 : "/bank-accounts");
                  }}
                >
                  {configData && configData.side_menu_icon_4 ? <img src={configData.side_menu_icon_4} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i className="bi-people"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                  <div style={{ color: configData ? configData.text_color : "" }}>{configData && configData.side_menu_text_4 ? configData.side_menu_text_4 : t("Bank_Accounts")}</div>
                  <i className="bi bi-chevron-right"></i>
                </a>
              ) : (
                <a
                  href="/#"
                  id="nav-pages"
                  className="list-group-item"
                  style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/signin");
                  }}
                >
                  {configData && configData.side_menu_icon_4 ? <img src={configData.side_menu_icon_4} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i className="bi-people"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                  <div style={{ color: configData ? configData.text_color : "" }}>{configData && configData.side_menu_text_4 ? configData.side_menu_text_4 : t("Bank_Accounts")}</div>
                  <i className="bi bi-chevron-right"></i>
                </a>
              )
            }
            {
              !(configData && configData.side_menu_active_5) ? null : currentUser ? (
              <a
                data-bs-toggle="offcanvas"
                data-bs-target="#
                menu-highlights"
                href="/#"
                className="list-group-item"
                style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(configData && configData.side_menu_route_5 ? configData.side_menu_route_5 : "/deposit");
                }}
              >
                {configData && configData.side_menu_icon_5 ? <img src={configData.side_menu_icon_5} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i className="bi-credit-card"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                <div style={{ color: configData ? configData.text_color : "" }}>{configData && configData.side_menu_text_5 ? configData.side_menu_text_5 : t("Deposit")}</div>
                <i className="bi bi-chevron-right"></i>
              </a>
            ) : (
              <a
                data-bs-toggle="offcanvas"
                data-bs-target="#menu-highlights"
                href="/#"
                className="list-group-item"
                style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/signin");
                }}
              >
                {configData && configData.side_menu_icon_5 ? <img src={configData.side_menu_icon_5} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i className="bi-credit-card"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                <div style={{ color: configData ? configData.text_color : "" }}>{configData && configData.side_menu_text_5 ? configData.side_menu_text_5 : t("Deposit")}</div>
                <i className="bi bi-chevron-right"></i>
              </a>
            )}
            {
              !(configData && configData.side_menu_active_6) ? null : currentUser ? (
              <a
                data-bs-toggle="offcanvas"
                data-bs-target="#me
                nu-highlights"
                href="/#"
                className="list-group-item"
                style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(configData && configData.side_menu_route_6 ? configData.side_menu_route_6 : "/withdraw");
                }}
              >
                {configData && configData.side_menu_icon_6 ? <img src={configData.side_menu_icon_6} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i className="bi-cash"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                <div style={{ color: configData ? configData.text_color : "" }}>{configData && configData.side_menu_text_6 ? configData.side_menu_text_6 : t("Withdrawal")}</div>
                <i className="bi bi-chevron-right"></i>
              </a>
            ) : (
              <a
                data-bs-toggle="offcanvas"
                data-bs-target="#menu-highlights"
                href="/#"
                className="list-group-item"
                style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/signin");
                }}
              >
                {configData && configData.side_menu_icon_6 ? <img src={configData.side_menu_icon_6} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i className="bi-cash"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                <div style={{ color: configData ? configData.text_color : "" }}>{configData && configData.side_menu_text_6 ? configData.side_menu_text_6 : t("Withdrawal")}</div>
                <i className="bi bi-chevron-right"></i>
              </a>
            )}
            {
              !(configData && configData.side_menu_active_7) ? null : currentUser ? (
              <a
                data-bs-toggle="offcanvas"
                data-bs-target="#
                menu-highlights"
                href="/#"
                className="list-group-item"
                style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(configData && configData.side_menu_route_7 ? configData.side_menu_route_7 : "/history");
                }}
              >
                {configData && configData.side_menu_icon_7 ? <img src={configData.side_menu_icon_7} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i className="bi-clock-history"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                <div style={{ color: configData ? configData.text_color : "" }}>{configData && configData.side_menu_text_7 ? configData.side_menu_text_7 : t("Orders_History")}</div>
                <i className="bi bi-chevron-right"></i>
              </a>
            ) : (
              <a
                data-bs-toggle="offcanvas"
                data-bs-target="#menu-highlights"
                href="/#"
                className="list-group-item"
                style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/signin");
                }}
              >
                {configData && configData.side_menu_icon_7 ? <img src={configData.side_menu_icon_7} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i className="bi-clock-history"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                <div style={{ color: configData ? configData.text_color : "" }}>{configData && configData.side_menu_text_7 ? configData.side_menu_text_7 : t("Orders_History")}</div>
                <i className="bi bi-chevron-right"></i>
              </a>
            )}
            {
              !(configData && configData.side_menu_active_8) ? null : currentUser ? (
              <a
                data-bs-toggle="offcanvas"
                data-bs-target="#m
                enu-highlights"
                href="/#"
                className="list-group-item"
                style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(configData && configData.side_menu_route_8 ? configData.side_menu_route_8 : "/bet-history");
                }}
              >
                {configData && configData.side_menu_icon_8 ? <img src={configData.side_menu_icon_8} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i className="bi-clock-history"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                <div style={{ color: configData ? configData.text_color : "" }}>{configData && configData.side_menu_text_8 ? configData.side_menu_text_8 : t("Bet_History")}</div>
                <i className="bi bi-chevron-right"></i>
              </a>
            ) : (
              <a
                data-bs-toggle="offcanvas"
                data-bs-target="#menu-highlights"
                href="/#"
                className="list-group-item"
                style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/signin");
                }}
              >
                {configData && configData.side_menu_icon_8 ? <img src={configData.side_menu_icon_8} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i className="bi-clock-history"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                <div style={{ color: configData ? configData.text_color : "" }}>{configData && configData.side_menu_text_8 ? configData.side_menu_text_8 : t("Bet_History")}</div>
                <i className="bi bi-chevron-right"></i>
              </a>
            )}
            {
              
              !(configData && configData.side_menu_active_9) ? null : currentUser ? (
                <a
                  href="/#"
                  id="nav-pages"

                  className="list-group-item"
                  style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(configData && configData.side_menu_route_9 ? configData.side_menu_route_9 : "/change-password");
                  }}
                >
                  {configData && configData.side_menu_icon_9 ? <img src={configData.side_menu_icon_9} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i className="bi-people"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                  <div style={{ color: configData ? configData.text_color : "" }}>{configData && configData.side_menu_text_9 ? configData.side_menu_text_9 : t("Change_Password")}</div>
                  <i className="bi bi-chevron-right"></i>
                </a>
              ) : (
                <a
                  href="/#"
                  id="nav-pages"
                  className="list-group-item"
                  style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/signin");
                  }}
                >
                  {configData && configData.side_menu_icon_9 ? <img src={configData.side_menu_icon_9} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i className="bi-people"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                  <div style={{ color: configData ? configData.text_color : "" }}>{configData && configData.side_menu_text_9 ? configData.side_menu_text_9 : t("Change_Password")}</div>
                  <i className="bi bi-chevron-right"></i>
                </a>
              )
            }
            {
              !(configData && configData.side_menu_active_10) ? null : currentUser ? (
              <a
                data-bs-toggle="offcanvas"
                data-bs-target="#
                menu-highlights"
                href="/#"
                className="list-group-item"
                style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(configData && configData.side_menu_route_10 ? configData.side_menu_route_10 : "/contactus");
                }}
              >
                {configData && configData.side_menu_icon_10 ? <img src={configData.side_menu_icon_10} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i className="bi-telephone"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                <div style={{ color: configData ? configData.text_color : "" }}>{configData && configData.side_menu_text_10 ? configData.side_menu_text_10 : t("Contact_Us")}</div>
                <i className="bi bi-chevron-right"></i>
              </a>
            ) : (
              <a
                data-bs-toggle="offcanvas"
                data-bs-target="#menu-highlights"
                href="/#"
                className="list-group-item"
                style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/signin");
                }}
              >
                {configData && configData.side_menu_icon_10 ? <img src={configData.side_menu_icon_10} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i className="bi-telephone"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                <div style={{ color: configData ? configData.text_color : "" }}>{configData && configData.side_menu_text_10 ? configData.side_menu_text_10 : t("Contact_Us")}</div>
                <i className="bi bi-chevron-right"></i>
              </a>
            )}
            {
              !(configData && configData.side_menu_active_11) ? null : currentUser ? (
              <a
                data-bs-toggle="offcanvas"
                data-bs-target="#
                menu-highlights"
                href="/#"
                className="list-group-item"
                style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(configData && configData.side_menu_route_11 ? configData.side_menu_route_11 : "/profile");
                }}
              >
                {configData && configData.side_menu_icon_11 ? <img src={configData.side_menu_icon_11} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i className="bi-person-square"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                <div style={{ color: configData ? configData.text_color : "" }}>{configData && configData.side_menu_text_11 ? configData.side_menu_text_11 : t("Profile")}</div>
                <i className="bi bi-chevron-right"></i>
              </a>
            ) : (
              <a
                data-bs-toggle="offcanvas"
                data-bs-target="#menu-highlights"
                href="/#"
                className="list-group-item"
                style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/signin");
                }}
              >
                {configData && configData.side_menu_icon_11 ? <img src={configData.side_menu_icon_11} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i className="bi-person-square"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                <div style={{ color: configData ? configData.text_color : "" }}>{configData && configData.side_menu_text_11 ? configData.side_menu_text_11 : t("Profile")}</div>
                <i className="bi bi-chevron-right"></i>
              </a>
            )}
            {
              !(configData && configData.side_menu_active_12) ? null : currentUser ? (
              <a
                data-bs-toggle="offcanvas"
                data-bs-target="#
                menu-highlights"
                href="/#"
                className="list-group-item"
                style={{backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color : configData?configData.text_color :""}}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(configData && configData.side_menu_route_12 ? configData.side_menu_route_12 :"/vip-ranking");
                }}
              >
                {configData&&configData.side_menu_icon_12?<img src={configData.side_menu_icon_12} alt="" width="30px" height="30px" style={{objectFit:"contain"}}/>:<i className="bi-person-square"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                <div style={{color : configData?configData.text_color:""}}>{configData && configData.side_menu_text_12 ? configData.side_menu_text_12 : t("VIP_Ranking")}</div>
                <i className="bi bi-chevron-right"></i>
              </a>
            ) : (
              <a
                data-bs-toggle="offcanvas"
                data-bs-target="#menu-highlights"
                href="/#"
                className="list-group-item"
                style={{backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color : configData?configData.text_color :""}}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/signin");
                }}
              >
                {configData&&configData.side_menu_icon_12?<img src={configData.side_menu_icon_12} alt="" width="30px" height="30px" style={{objectFit:"contain"}}/>:<i className="bi-people"></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                <div style={{color : configData?configData.text_color:""}}>{configData && configData.side_menu_text_12 ? configData.side_menu_text_12 : t("VIP_Ranking")}</div>
                <i className="bi bi-chevron-right"></i>
              </a>
            )}

          {
            configData && (configData.language_toggle_position == "sidebar")?
            <a
              // data-bs-toggle="offcanvas"
              data-bs-target="#menu-highlights"
              href="/#"
              className="list-group-item flex-column px-0"
              style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <div style={{borderBottom: "1px solid #000",}} className="d-flex align-items-center pl-2 pb-2 w-100 position-relative" onClick={() => {
                setLanguageOpen(!languageOpen)
              }}>
                {configData && configData.language_icon ? <img src={configData.language_icon} alt="" width="30px" height="30px" style={{ objectFit: "contain" }} /> : <i class="bi bi-globe2" style={{ fontSize: "24px", color: "#333" }}></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                <div style={{ color: configData ? configData.text_color : "" }}>{language? language.label: "Select Language"}</div>
                <i className="bi bi-chevron-right" style={{right: "0"}}></i>
              </div>
              <div className="d-flex flex-column w-100 list-submenu px-0" style={{height: "auto", overflowY: "hidden", maxHeight: languageOpen?"2000px":"0", transition: ".5s"}}>
                {
                  countries.map(country => {
                    const all_languages = configData && configData.available_languages?country.supported_languages.filter(supported_language => configData.available_languages.split(",").includes(supported_language.lang)):country.supported_languages;
                    return (
                    <a
                      data-bs-toggle="offcanvas"
                      data-bs-target="#menu-highlights"
                      href="/#"
                      className={`list-group-item w-100 pb-2 pt-0 px-0 ${(all_languages.length<1)?"d-none":""}`}
                      style={{ paddingLeft: "0px", backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                      onClick={(e) => {
                        e.preventDefault();
                        // toggleLanguages();
                      }}
                    >
                      {/* {country.flag ? <img src={country.flag} alt="" width="20px" height="20px" style={{ objectFit: "contain" }} /> : <i class="bi bi-globe2" style={{ fontSize: "24px", color: "#333" }}></i>} bi bg-blue-light shadow-bg shadow-bg-xs */}
                      <div className="d-flex align-items-center flex-column w-100 pl-0" style={{paddingLeft: 0}}>
                        {
                          all_languages.map((supported_language, index) => {
                            return (
                              <p className="d-flex align-items-center pb-2 w-100" style={{paddingLeft: "50px", borderBottom: "1px solid #000",}}>
                                {country.flag ? <img src={country.flag} alt="" width="20px" height="20px" style={{ objectFit: "contain" }} /> : <i class="bi bi-globe2" style={{ fontSize: "24px", color: "#333" }}></i>} {/* bi bg-blue-light shadow-bg shadow-bg-xs */}
                                <div className="d-flex flex-column align-items-start" style={{ lineHeight: 1.2, paddingLeft: "10px", color: configData ? configData.text_color : "" }} onClick={() => {
                                  setLanguage({
                                    lang: supported_language.lang,
                                    label: supported_language.label,
                                    img: country.flag,
                                    country: country.country
                                  })
                                }}>
                                  <div className="align-self-start" style={{  lineHeight: 1.3, color: configData ? configData.text_color : ""}}> {country.label}</div>
                                  <div className="align-self-start" style={{  lineHeight: 1.3, fontSize: "12px", color: configData ? configData.text_color : ""}}> {supported_language.label}</div>
                                </div>
                              </p>
                            )
                          })
                        }
                      </div>
                    </a>
                  )})
                }
              </div>
            </a>
            :null
          }
          </div>

          <div className="divider divider-margins mb-3 opacity-50"></div>

          <div className="list-group list-custom list-menu-small">
            {currentUser ? (
              <div
                data-bs-toggle="offcanvas"
                data-bs-target="#menu-sidebar"
                className="list-group-item"
                style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                onClick={(e) => {
                  e.preventDefault();
                  AuthService.logout();
                  window.SBF.logout();
                  setCurrentUser(null);
                  sessionStorage.removeItem("popup_isonce_onsite")
                  navigate("/signin");
                  setAuth(false)
                }}
              >
                {configData && configData.logout_icon ? <img src={configData.logout_icon} alt="" width="30px" height="30px" style={{ marginRight: "10px", objectFit: "contain" }} /> : <i className="bi bi-bar-chart-fill opacity-20 font-16"></i>}
                <div style={{ color: configData ? configData.text_color : "" }}>{t("Log_out")}</div>
                <i className="bi bi-chevron-right"></i>
              </div>
            ) : (
              <div
                data-bs-toggle="offcanvas"
                data-bs-target="#menu-sidebar"
                className="list-group-item"
                style={{ backgroundColor: configData && configData.sidebar_menu_item_bg ? configData.sidebar_menu_item_bg : "#242424", color: configData ? configData.text_color : "" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/signin");
                }}
              >
                {configData && configData.login_icon ? <img src={configData.login_icon} alt="" width="30px" height="30px" style={{ marginRight: "10px", objectFit: "contain" }} /> : <i className="bi bi-bar-chart-fill opacity-20 font-16"></i>}
                <div style={{ color: configData ? configData.text_color : "" }}>{t("LOGIN")}</div>
                <i className="bi bi-chevron-right"></i>
              </div>
            )}
          </div>

          <div className="divider divider-margins opacity-50"></div>
        </div>
      </div>
    </div>
  );
}

export default MenuSidebar;
